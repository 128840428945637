/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Golos+Text');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: "Roboto", sans-serif;
  font-family: "Gill Sans", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.PhoneInputCountry {
  background-color: #e9ecef !important;
  padding: 3px !important;
  padding-left: 8px !important;
  margin-right: 0% !important;
  border: 1px solid #ced4da !important;
  border-right-width: 0px !important;
}
.PhoneInputInput {
  padding-left: 12px !important;
  border: 1px solid #ced4da !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  height: 39px !important;
}

.PhoneInput {
  flex: 1 !important;
}

.PhoneInputInput:focus {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #86b7fe !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  z-index: 1;
}
